import React, { useState, useEffect } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsSearch } from 'react-icons/bs';

import { reportPerProdStorage, reportPerProdSN, reportPerProdBC, csvPerProdStorage, csvPerProdSN, csvPerProdBC, reportPerProdPT, csvPerProdPT } from '../../data/dummy';
import { Button, ErrorLabel, ProductSearcher, Select, ReportsPDF, Banner } from '../../components';
import SelectOff from './SelectOff';
import { URL_PRODUCT, URL_PRODUCTTYPE, URL_STORAGE, URL_WAREHOUSEPRODUCTREPORT } from '../../services/Api';
import { getDataBy } from '../../services/GdrService';
import { useAuthContext } from '../../contexts/ContextAuth';
import { useStateContext } from '../../contexts/ContextProvider';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { BsFilePdf } from 'react-icons/bs';
import { GrDocumentCsv } from 'react-icons/gr';
import { FormatDesktop } from '../../components/Table';

const Warehouse = () => {
  const { auth } = useAuthContext();
  const { themeColors } = useStateContext();
  const initialState = { value: '', error: null };
  const [report, setReport] = useState(initialState);
  const [product, setProduct] = useState('');
  const [storage, setStorage] = useState('');
  const [recordsError, setRecordsError] = useState(initialState);
  const [info, setInfo] = useState({ grid: [] });
  const [records, setRecords] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showButton, setShowButton] = useState(null);
  const itemsPerLoad = 50;
  const [csv, setCSV] = useState([]);
  const [banner, setBanner] = useState(initialState);
  const [openSearcher, setOpenSearcher] = useState(false);
  const date = new Date();
  const aaaammdd = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${(date.getDate()).toString().padStart(2, '0')}`;
  const hhmmss = date.toTimeString().substring(0, 2) + date.toTimeString().substring(3, 5) + date.toTimeString().substring(6, 8);
  const ddmmaaaa = `${aaaammdd.slice(6, 8).padStart(2, '0')}/${aaaammdd.slice(4, 6).padStart(2, '0')}/${aaaammdd.slice(0, 4)}`;
  const hh_mm_ss = `${hhmmss.slice(0, 2)}:${hhmmss.slice(2, 4)}:${hhmmss.slice(4, 6)}`;
  const { title } = info;
  const config = [
    { id: 'producto', nombre: 'Productos por Almacén', },
    { id: 'productoNS', nombre: 'Productos por Almacén con Número de Serie', },
    { id: 'productoBC', nombre: 'Productos por Almacén con Códigos de Barra', },
    { id: 'tipoproducto', nombre: 'Productos por Almacén según Tipo de Producto', },
    { id: 'almacenproducto', nombre: 'Historial de Producto según Almacén', },
  ]

  useEffect(() => {
    let shadowBanner = setTimeout(() => setBanner({ error: null }), 2000);
    return () => { clearTimeout(shadowBanner) };
  });

  const selectGrid = (aReport) => {
    const info = {
      producto: { grid: reportPerProdStorage, csv: csvPerProdStorage, title: 'Productos por Almacén', },
      productoNS: { grid: reportPerProdSN, csv: csvPerProdSN, title: 'Productos por Almacén con Número de Serie', },
      productoBC: { grid: reportPerProdBC, csv: csvPerProdBC, title: 'Productos por Almacén con Códigos de Barra', },
      tipoproducto: { grid: reportPerProdPT, csv: csvPerProdPT, title: 'Productos por Almacén según Tipo de Producto', },
      almacenproducto: { grid: reportPerProdStorage, csv: csvPerProdStorage, title: 'Historial de Producto según Almacén', },
    };

    setInfo(info[aReport])
  }

  const transformReport = (anObject, aReport) => {
    const getter = {
      producto: 'nom_producto',
      productoNS: 'nom_producto',
      productoBC: 'nom_producto',
      tipoproducto: 'nom_tipoproducto',
      almacenproducto: 'nom_almacen',
    };

    selectGrid(aReport)

    return anObject.reduce(function (reducer, array) {
      reducer[array[getter[aReport]]] = reducer[array[getter[aReport]]] || [];
      reducer[array[getter[aReport]]].push(array);
      return reducer;
    }, Object.create(null));
  }

  const handleReport = (aReport, anStorage, aProduct) => {
    anStorage = anStorage === '' || anStorage === undefined ? 0 : anStorage;
    aProduct = aProduct === '' || aProduct === undefined ? 0 : aProduct;

    if (aReport.value !== '' && aReport.error === false) {
      const params = anStorage + '/' + aProduct
      getDataBy(URL_WAREHOUSEPRODUCTREPORT + aReport.value + '/' + params, auth?.token)
        .then(response => {
          if (response.data.length === 0) {
            setData([])
            setRecords([])
            setRecordsError({ value: `Ups! No hay datos para mostrar ${product.nombre === undefined ? '' : ' en' + product.nombre}`, error: false })
          } else {
            const transformedData = transformReport(response.data, aReport.value);

            if(anStorage === 0 || aProduct === 0){
              setData(transformedData)
              // Cargamos algunos registros iniciales
              const initialRecords = Object.values(transformedData).flatMap(item => item).slice(0, itemsPerLoad);
              setRecords(transformReport(initialRecords, aReport.value));
              setCurrentPage(1);
              setShowButton(true);
            }else{
              setData([])
              setRecords(transformedData)
              setShowButton(null);
            }
            
            setRecordsError({ error: false })
          }
          setCSV(response.data)
          setProduct({ id: '' })
          setStorage({ id: '' })
          aReport.value === 'almacenproducto' && setStorage({ id: '' })
          setBanner({ ...banner, value: { text: 'Reporte generado correctamente!', background: themeColors?.confirm }, error: true })
        })
        .catch(() => {
          setRecordsError({ value: 'Ocurrió un error al generar el Reporte', error: true })
          setShowButton(null);
        })
      } else {
        setRecordsError({ value: 'Elija un Reporte para realizar la búsqueda', error: true })
        setShowButton(null);
    }
  }

  // Función para cargar más registros cuando se presiona el botón "Cargar más"
  const loadMoreRecords = (aReport) => {
    const start = currentPage * itemsPerLoad;
    const end = start + itemsPerLoad;
    const oldRecords = Object.values(records).flatMap(item => item);
    const newRecords = Object.values(data).flatMap(item => item).slice(start, end);
    const transformedData = transformReport([...oldRecords, ...newRecords], aReport.value);

    setRecords(transformedData);
    setCurrentPage(prevPage => prevPage + 1);

    if(Object.keys(records).length >= Object.keys(data).length){
      setShowButton(null)
    }
  };

  const handleCSV = () => {
    const aux = csv.map(item => {
      const record = {};
      info['csv'].forEach(column => {
        record[column.name] = item[column.field];
      });
      return record;
    });
    return aux;
  }

  const openSearch = () => setOpenSearcher(true)

  return (
    <>
      <div className='absolute -top-14 -left-10'>
        {banner.error !== null && <Banner text={banner.value.text} backgroundColor={banner.value.background} setState={() => setBanner(initialState)} />}
      </div>
      <div className='flex gap-2'>
        <SelectOff data={config} id='report' label='Reporte' state={report} setState={setReport} />
        {report.value !== '' && <>
          <Select id='warehouse' label='Almacén (Sin definir para todos)' url={URL_STORAGE} state={storage} setState={setStorage} getter='nombre' />
          <TooltipComponent content='Ver productos en Almacén' position="TopCenter">
            <button type='button' onClick={openSearch} onKeyDown={(event) => event.key === 'Escape' && openSearch()} style={{ backgroundColor: themeColors?.secondary }} className='relative p-2 text-white dark:text-black text-2xl rounded-md'>
              <BsSearch />
            </button>
          </TooltipComponent>
          {openSearcher === true && !!storage.id && <ProductSearcher title={`Productos en ${storage.nombre}`} product={product} setProduct={setProduct} warehouse={storage.id} setClose={setOpenSearcher} />}
          {report.value !== 'almacenproducto' && report.value !== 'tipoproducto' && 
            <Select id='product' label='Producto (Sin definir para todos)' url={URL_PRODUCT} state={product} setState={setProduct} getter='nombre' /> }
          {report.value !== 'almacenproducto' && report.value === 'tipoproducto' &&
            <Select id='product' label='Tipo de Producto (Sin definir para todos)' url={URL_PRODUCTTYPE} state={product} setState={setProduct} getter='nombre' /> }
        </>}
        <Button customFunction={() => handleReport(report, storage.id, product.id)} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='1/2' text='Generar reporte' />
      </div>
      <div>
        <div className='text-center p-2 pt-6 text-xs'>
          {recordsError.error === true && <ErrorLabel color={themeColors?.error}>{recordsError.value}</ErrorLabel>}
          {recordsError.error === null && records.length === 0 && <span style={{ color: themeColors?.highEmphasis }}>Ups! No hay datos para mostrar aquí</span>}
        </div>
        {Object.keys(records).length > 0 &&
          <div className='flex justify-end gap-2 mt-2'>
            <PDFDownloadLink
              document={<ReportsPDF header={info.grid} data={records} info={{ title, ddmmaaaa, hh_mm_ss, receipt: `${aaaammdd}${hhmmss}` }} />}
              fileName={`${aaaammdd}${hhmmss}_Reporte-${info.title}-PDF.pdf`}>
              <TooltipComponent content='Exportar tabla a PDF' position="TopCenter">
                <Button customFunction={() => { }} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='' icon={<BsFilePdf />} />
              </TooltipComponent>
            </PDFDownloadLink>
            <CSVLink data={handleCSV()} filename={`${aaaammdd}${hhmmss}_Reporte-${info.title}-CSV.csv`} separator=', ' enclosingCharacter={`'`} target='_blank'>
              <TooltipComponent content='Exportar tabla a CSV' position="TopCenter">
                <Button customFunction={() => { }} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='' icon={<GrDocumentCsv />} />
              </TooltipComponent>
            </CSVLink>
          </div>
        }
        <div className='flex flex-col'>
          {recordsError.error === false &&
            (Object.keys(records).length > 0
              ? Object.entries(records).map(([key, records]) => (
                <div key={key}>
                  <p style={{ color: themeColors?.primary }} className="m-3 mt-4 uppercase text-xl">
                    {key}
                  </p>
                  <div style={{ width: 'calc(100 / 7% - 20px)' }} className='ml-6 overflow-auto rounded-lg shadow hidden md:block border'>
                    <table className='w-full table-auto'>
                      <thead className='bg-gray-50 dark:bg-secondary-dark-bg dark:text-gray-100 border-b-2 border-gray-200 dark:border-black'>
                        <tr>
                          {info.grid.map((item, index) => (
                            <th key={index} className='p-3 text-sm font-semibold tracking-wide text-left'>{item.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((record, index) =>
                          <tr key={index} className='bg-white dark:bg-secondary-dark-bg odd:bg-gray-300 dark:odd:bg-neutral-700 hover:bg-neutral-100 dark:hover:bg-gray-500'>
                            {info.grid.map((property, key) =>
                              <td key={key} className='w-fit p-3 text-sm text-gray-700 dark:text-gray-100 whitespace-nowrap'>
                                <FormatDesktop data={record} property={property} />
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
              : <span className='text-center' style={{ color: themeColors?.highEmphasis }}>{recordsError.value}</span>
            )
          }
        </div>
        {showButton && 
        <>
          <div className='flex flex-row justify-center items-end gap-4 pt-4'>
            <Button customFunction={() => loadMoreRecords(report)} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='1/5' text='Cargar más...' />
            <div className='absolute right-10'>
              <span className="text-sm text-gray-700 dark:text-gray-400 just">
                Mostrando{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {Object.keys(records).length}
                </span>{" "}
                de{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {Object.keys(data).length}
                </span>{" "}
                Entradas
              </span>
            </div>
          </div>
        </>
        }
      </div>
    </>
  )
}

export default Warehouse