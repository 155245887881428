import React, { useState, useEffect } from 'react';

import { regEx, reportPerWarehouse, reportPerProduct, reportPerSerial, csvPerWarehouse, csvPerProduct, csvPerSerial } from '../../data/dummy';
import { Banner, Button, ErrorLabel, Input, ReportsPDF } from '../../components';
import SelectOff from './SelectOff';
import { getDataBy } from '../../services/GdrService';
import { useAuthContext } from '../../contexts/ContextAuth';
import { useStateContext } from '../../contexts/ContextProvider';
import { URL_REPORT } from '../../services/Api';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { BsFilePdf } from 'react-icons/bs';
import { GrDocumentCsv } from 'react-icons/gr';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FormatDesktop } from '../../components/Table';

const General = () => {
    const { auth } = useAuthContext();
    const { themeColors } = useStateContext();
    const initialState = { value: '', error: null };
    const [report, setReport] = useState(initialState);
    const [serialNumber, setSerialNumber] = useState(initialState);
    const [recordsError, setRecordsError] = useState(initialState);
    const [info, setInfo] = useState({ grid: [] });
    const [records, setRecords] = useState([]);
    const [csv, setCSV] = useState([]);
    const [banner, setBanner] = useState(initialState);
    const date = new Date();
    const aaaammdd = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${(date.getDate()).toString().padStart(2, '0')}`;
    const hhmmss = date.toTimeString().substring(0, 2) + date.toTimeString().substring(3, 5) + date.toTimeString().substring(6, 8);
    const ddmmaaaa = `${aaaammdd.slice(6, 8).padStart(2, '0')}/${aaaammdd.slice(4, 6).padStart(2, '0')}/${aaaammdd.slice(0, 4)}`;
    const hh_mm_ss = `${hhmmss.slice(0, 2)}:${hhmmss.slice(2, 4)}:${hhmmss.slice(4, 6)}`;
    const { title } = info;
    const config = [
        { id: 'stockProdPorAlmacen', nombre: 'Stock Total de Productos Agrupados por Almacén', },
        { id: 'stockProd', nombre: 'Stock Total de Productos', },
        { id: 'historialNumeroSerie', nombre: 'Historial de Número de Serie', },
    ]

    useEffect(() => {
        let shadowBanner = setTimeout(() => setBanner({ error: null }), 2000);
        return () => { clearTimeout(shadowBanner) };
    });

    const selectGrid = (aReport) => {
        const info = {
            stockProdPorAlmacen: { grid: reportPerWarehouse, csv: csvPerWarehouse, title: 'Stock Total de Productos Agrupados por Almacén', },
            stockProd: { grid: reportPerProduct, csv: csvPerProduct, title: 'Stock Total de Productos', },
            historialNumeroSerie: { grid: reportPerSerial, csv: csvPerSerial, title: 'Historial de Número de Serie', },
        };

        setInfo(info[aReport])
    }

    const transformReport = (anObject, aReport) => {
        const getter = {
            stockProdPorAlmacen: 'almacen',
            stockProd: 'nombre',
            historialNumeroSerie: 'SerialNumber',
        };

        selectGrid(aReport)

        return anObject.reduce(function (reducer, array) {
            reducer[array[getter[aReport]]] = reducer[array[getter[aReport]]] || [];
            reducer[array[getter[aReport]]].push(array);
            return reducer;
        }, Object.create(null));
    }

    const handleReport = (aReport) => {
        if (aReport.value !== '' && aReport.error === false) {
            getDataBy(URL_REPORT + aReport.value + '/' + serialNumber.value, auth?.token)
                .then(response => {
                    setRecords(transformReport(response.data, aReport.value))
                    setCSV(response.data)
                    setRecordsError({ error: false })
                    setBanner({ ...banner, value: { text: 'Reporte generado correctamente!', background: themeColors?.confirm }, error: true })
                })
                .catch(() => setRecordsError({ value: 'Ocurrió un error al generar el Reporte', error: true }))

            setSerialNumber(initialState)
        } else {
            setRecordsError({ value: 'Elija un Reporte para realizar la búsqueda', error: true })
        }
    }

    const handleCSV = () => {
        const aux = csv.map(item => {
            const record = {};
            info['csv'].forEach(column => {
                record[column.name] = item[column.field];
            });
            return record;
        });
        return aux;
    }

    return (
        <>
            <div className='absolute -top-14 -left-10'>
                {banner.error !== null && <Banner text={banner.value.text} backgroundColor={banner.value.background} setState={() => setBanner(initialState)} />}
            </div>
            <div className='flex gap-2'>
                <SelectOff data={config} id='report' label='Reporte' state={report} setState={setReport} />
                {report.value === 'historialNumeroSerie' &&
                    <Input id='serial' type='text' label='Ingrese número de serie' size='small' css='w-1/2'
                        required={true} state={serialNumber} setState={setSerialNumber} regEx={regEx.alphanumeric} />
                }
                <Button customFunction={() => handleReport(report)} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='1/3' text='Generar reporte' />
            </div>
            <div className='text-center p-2 pt-4 text-xs'>
                {recordsError.error === true && <ErrorLabel color={themeColors?.error}>{recordsError.value}</ErrorLabel>}
                {recordsError.error === null && Object.keys(records).length === 0 && <span style={{ color: themeColors?.highEmphasis }}>Ups! No hay datos para mostrar aquí</span>}
            </div>
            {Object.keys(records).length > 0 &&
                <div className='flex justify-end gap-2 mt-2'>
                    <PDFDownloadLink
                        document={<ReportsPDF header={info.grid} data={records} info={{ title, ddmmaaaa, hh_mm_ss, receipt: `${aaaammdd}${hhmmss}` }} />}
                        fileName={`${aaaammdd}${hhmmss}_Reporte-${info.title}-PDF.pdf`}>
                        <TooltipComponent content='Exportar tabla a PDF' position="TopCenter">
                            <Button customFunction={() => { }} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='' icon={<BsFilePdf />} />
                        </TooltipComponent>
                    </PDFDownloadLink>
                    <CSVLink data={handleCSV()} filename={`${aaaammdd}${hhmmss}_Reporte-${info.title}-CSV.csv`} separator=', ' enclosingCharacter={`'`} target='_blank'>
                        <TooltipComponent content='Exportar tabla a CSV' position="TopCenter">
                            <Button customFunction={() => { }} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='' icon={<GrDocumentCsv />} />
                        </TooltipComponent>
                    </CSVLink>
                </div>
            }
            <div>
                <div className='flex flex-col'>
                    {recordsError.error === false && Object.keys(records).length > 0 &&
                        Object.entries(records).map(([key, records]) => (
                            <div key={key}>
                                <p style={{ color: themeColors?.primary }} className="m-3 mt-2 uppercase text-xl">
                                    {key}
                                </p>
                                <div style={{ width: 'calc(100 / 7% - 20px)' }} className='ml-6 overflow-auto rounded-lg shadow hidden md:block border'>
                                    <table className='w-full table-auto'>
                                        <thead className='bg-gray-50 dark:bg-secondary-dark-bg dark:text-gray-100 border-b-2 border-gray-200 dark:border-black'>
                                            <tr>
                                                {info.grid.map((item, index) => (
                                                    <th key={index} className='p-3 text-sm font-semibold tracking-wide text-left'>{item.name}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.map((record, index) =>
                                                <tr key={index} className='bg-white dark:bg-secondary-dark-bg odd:bg-gray-300 dark:odd:bg-neutral-700 hover:bg-neutral-100 dark:hover:bg-gray-500'>
                                                    {info.grid.map((property, key) =>
                                                        <td key={key} className='w-fit p-3 text-sm text-gray-700 dark:text-gray-100 whitespace-nowrap'>
                                                            <FormatDesktop data={record} property={property} />
                                                        </td>
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default General