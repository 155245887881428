import React, { useState, useEffect } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsCloudArrowDown } from 'react-icons/bs';
import { Document, Page, View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer';

import { dataIncomePDF, transferReportGrid, dataExpensePDF, dataMovsPDF, incomeReportGrid, storagePDF, clientPDF, providerPDF } from '../data/dummy';
import { getDataBy, getDataFrom } from '../services/GdrService';
import agLogo from '../data/agSistemasBase.png';
import regular from '../data/fonts/RobotoMono-Regular.ttf';
import bold from '../data/fonts/RobotoMono-Bold.ttf';

const Dates = ({ date }) => {
    const fullDate = new Date(date);
    const formatDate = (date) => date < 10 ? `0${date}` : date
    const year = formatDate(fullDate.getFullYear());
    const month = formatDate(fullDate.getMonth() + 1);
    const day = formatDate(fullDate.getDate());
    const hours = formatDate(fullDate.getHours());
    const seconds = formatDate(fullDate.getMinutes());

    return (
        <Text>
            {year}-{month}-{day} {hours}:{seconds}
        </Text>
    )
};

const formatPrice = (aPrice) => {
    const number = parseInt(aPrice)
    const format = new Intl.NumberFormat('es-419', { minimumFractionDigits: 2 })
    const numberParts = format.format(number).toString().split('.');

    return numberParts[0].replace(/,/g, '.') + ',' + numberParts[1]
}

const FormatDesktop = ({ data, property }) => {

    if (property.field === 'fecha') {
        const date = new Date(data[property.field])
        const [day, month, year] = [date.getDate(), date.getMonth() + 1, date.getFullYear()]

        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
    }

    if (property.field === 'Egreso_Nro')
        return (data[property.field] === 0 ? 'N/A' : data[property.field])

    if (property.field === 'url') {
        return (
            <a href={data[property.field]}
                className='flex items-center gap-1 font-bold hover:underline'
                target='_blank' rel="noreferrer" download='filename' >
                Descargar <Text className='text-2xl'><BsCloudArrowDown /></Text>
            </a>
        );
    }

    if (property.field === 'unitPrice' || property.field === 'price' || property.field === 'VAT' || property.field === 'subTotal'
        || property.field === 'precio' || property.field === 'total' || property.field === 'subtotal' || property.field === 'totaliva'
        || property.field === 'precio_detalle' || property.field === 'iva_detalle' || property.field === 'subtotal_detalle' || property.field === 'cmp') {
        return ('$' + formatPrice(data[property.field]));
    }

    if (property.field === 'Fecha Creación' || property.field === 'lastlogin' || property.field === 'createdAt') {
        let date = new Date(data[property.field]);
        date = new Date(date.getTime() + 3 * 60 * 60 * 1000);
        return (<Dates date={date} />);
    }

    if (property.field === 'alicuota') {
        return (<Text>{data[property.field]}%</Text>);
    }

    if (property.field === 'validateAccount') {
        const today = new Date();
        let expires = new Date(data.validateAccountExpires);
        expires = new Date(expires.getTime() + 3 * 60 * 60 * 1000);

        if ((data[property.field] === null || data[property.field] === false) && expires < today) {
            return (
                <TooltipComponent content={`Reenviar validación a ${data.email}`} position="BottomCenter">
                    <Text className='p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 dark:bg-red-800 dark:text-red-200 rounded-lg bg-opacity-50'>
                        Sin validar
                    </Text>
                </TooltipComponent>
            );
        }
        if (expires >= today)
            return (<Text className='p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 dark:bg-red-800 dark:text-red-200 rounded-lg bg-opacity-50'>Sin validar</Text>);

        return (<Text className='p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 dark:bg-green-800 dark:text-green-200 rounded-lg bg-opacity-50'>Validado</Text>);
    }

    if (property.field === 'validateAccountExpires') {
        const today = new Date();
        let expires = new Date(data[property.field]);
        expires = new Date(expires.getTime() + 3 * 60 * 60 * 1000);

        if (Number(data.validateAccount) === 1)
            return (<Text className=''></Text>);
        if (expires >= today)
            return (<Text className='p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 dark:bg-yellow-800 dark:text-yellow-200 rounded-lg bg-opacity-50'>Pendiente</Text>);
        if (expires < today)
            return (<Text className='p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 dark:bg-red-800 dark:text-red-200 rounded-lg bg-opacity-50'>Rechazado</Text>);
    }

    if (property.field === 'resetPasswordExpires') {
        const today = new Date();
        if (data.resetPasswordExpires === null)
            return (<Text className=''></Text>);
        if (new Date(data[property.field]) <= today)
            return (<Text className='p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 dark:bg-yellow-800 dark:text-yellow-200 rounded-lg bg-opacity-50'>Pendiente</Text>);

        return (<Text className='p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 dark:bg-red-800 dark:text-red-200 rounded-lg bg-opacity-50'>Expirado</Text>);
    }

    return (<Text>{data[property.field]}</Text>);
}

const VerticalPDF = ({ id, info }) => {
    const [data, setData] = useState([]);
    const [details, setDetails] = useState([]);
    const [source, setSource] = useState([]);
    const headers = {
        'Ingreso': { grData: dataIncomePDF, grDetails: incomeReportGrid, grSource: providerPDF },
        'Egreso': { grData: dataExpensePDF, grDetails: incomeReportGrid, grSource: clientPDF },
        'Movimiento': { grData: dataMovsPDF, grDetails: transferReportGrid, grSource: storagePDF },
    }

    const fechaCompra = data.length > 0 ? new Date(data[0].fechacompra) : new Date();
    const fechaEgreso = data.length > 0 ? new Date(data[0].fechaegreso) : new Date();
    const fechaTransferencia = data.length > 0 ? new Date(data[0].fechamovimiento) : new Date();
    const fechaMovimiento = info.title === 'Ingreso' ? transformDate(fechaCompra) : info.title === 'Egreso' ? transformDate(fechaEgreso) : transformDate(fechaTransferencia);

    function transformDate(aDate) {
        const [month, day, year] = [aDate.getMonth() + 1, aDate.getDate(), aDate.getFullYear()];

        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const map = {
            'Ingreso': { url: 'compraproducto/', detail: '/q/detallecompra/', source: 'proveedor/', fk: 'fk_proveedor', },
            'Egreso': { url: 'egresoproducto/', detail: '/q/detalleegreso/', source: 'cliente/', fk: 'fk_cliente', },
            'Movimiento': { url: 'movimiento/', detail: '/q/detallemovimiento/', source: 'almacen/', fk: 'id_almacen_destino', },
        }
        const URL = `${map[info.title].url}${id}`;
        const DETALLE_URL = `${map[info.title].url}${map[info.title].detail}${id}`
        const SOURCE_URL = `${map[info.title].source}`

        const saveResponse = (data) => {
            return Array.isArray(data) ? data : [data];
        }

        const getRecords = async () => {
            await getDataFrom(URL, signal, info.auth)
                .then(async response => {
                    setData(saveResponse(response.data))
                    await getDataBy(DETALLE_URL, info.auth)
                        .then(response => setDetails(saveResponse(response.data)))
                    await getDataBy(SOURCE_URL + (Array.isArray(response.data) ? response.data[0][map[info.title].fk] : response.data[map[info.title].fk]), info.auth)
                        .then(response => setSource(saveResponse(response.data)))
                })
                .catch(error => console.log(error))
        }

        getRecords();

        return () => { controller.abort(); };
    }, [id, info])

    Font.register({
        family: 'Roboto', format: 'truetype', fonts: [
            { src: regular },
            { src: bold, fontWeight: 500 },
        ]
    });

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            fontFamily: 'Roboto',
            fontWeight: 400,
            color: '#222',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        cabecera: {
            fontSize: 10,
            paddingVertical: 5,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'center'
        },
        info: {
            fontSize: 10,
            color: '#8C979A',
        },
        table: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            margin: '0 auto',
            fontSize: 10,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        column: {
            width: 100,
            backgroundColor: '#ffffff',
            paddingLeft: '10px',
            textAlign: 'left',
            maxLines: 2,
        },
        columnEven: {
            width: 100,
            backgroundColor: '#deedf0',
            paddingLeft: '10px',
            textAlign: 'left',
            maxLines: 2,
        },
        total: {
            position: 'absolute',
            width: '100%',
            bottom: 50,
            fontSize: 10, paddingVertical: 10, marginTop: 50,
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            padding: 10,
            marginVertical: 10,
            marginHorizontal: 35,
            borderTopWidth: '2px',
            borderStyle: 'solid',
            borderColor: '#000',
            fontSize: 10,
        }
    });

    return (
        <Document fileName={info.newTitle}>
            <Page size='A4' orientation='portrait' style={styles.body}>
                <View style={{ ...styles.header, paddingBottom: 20, borderBottomWidth: '1px', borderStyle: 'solid', borderColor: '#000', }} fixed>
                    <Image src={agLogo} alt='Logo AgSistemas' style={{ maxWidth: "90px", maxHeight: "90px" }} />

                    <Text style={{ width: '100%', textAlign: 'center', position: 'absolute', bottom: 0, fontSize: 22, paddingTop: 15 }} fixed>{info.title}</Text>

                    <View style={styles.info}>
                        <Text style={{ textTransform: 'uppercase', marginBottom: 1 }}>Reporte</Text>
                        <Text style={{ textTransform: 'uppercase', marginBottom: 1 }}>{info.title} {id}</Text>
                        <Text style={{ marginBottom: 1 }}>Fecha: {info.ddmmaaaa} </Text>
                        <Text style={{ marginBottom: 1 }}>Hora: {info.hh_mm_ss} </Text>
                    </View>
                </View>

                {source.length > 0 &&
                    (info.title === 'Movimiento' ?
                        <View style={styles.cabecera} fixed>
                            <Text style={{ margin: 2 }}>
                                <Text style={{ fontWeight: 500 }}>Almacén Origen: </Text>{data[0].nombre_almacen_origen}
                            </Text>
                            <Text style={{ margin: 2 }}>
                                <Text style={{ fontWeight: 500 }}>Almacén Destino: </Text>{data[0].nombre_almacen_destino}
                            </Text>
                            {data.map(item => headers[info.title].grSource.map((property, key) =>
                                <Text key={key} style={{ margin: 2 }}>
                                    <Text style={{ fontWeight: 500 }}>{property.name + ':'}</Text>{item[property.field]}
                                </Text>
                            ))}
                        </View>
                        :
                        <View style={styles.cabecera} fixed>
                            <Text style={{ margin: 2 }}>
                                <Text style={{ fontWeight: 500 }}>Señor/es: </Text>{source[0].nombre} <Text style={{ fontWeight: 500 }}>CUIT/CUIL: </Text>{source[0].id}
                            </Text>
                            <Text style={{ margin: 2 }}>
                                <Text style={{ fontWeight: 500 }}>Teléfono: </Text>{source[0].tel}
                            </Text>
                            <View style={{}}>
                                <Text style={{ margin: 2 }}>
                                    <Text style={{ fontWeight: 500 }}>Correo: </Text>{source[0].email}
                                </Text>
                                <Text style={{ margin: 2 }}>
                                    <Text style={{ fontWeight: 500 }}>Direccion:</Text>{source[0].direccion} (CP: {source[0].cp})
                                </Text>
                            </View>
                        </View>)
                }

                {data.length > 0 && details.length > 0 &&
                    <View style={{ ...styles.cabecera, position: 'absolute', top: 123, right: 30 }}>
                        <Text style={{ margin: 2 }}>
                            <Text style={{ fontWeight: 500 }}>Fecha: </Text>{fechaMovimiento}
                        </Text>
                        {info.title !== 'Movimiento' &&
                            <>
                                <Text style={{ margin: 2 }}>
                                    <Text style={{ fontWeight: 500 }}>{info.title === 'Egreso' ? 'Almacén Origen' : 'Almacén Destino'}: </Text>{details[0].nombre_almacen}
                                </Text>
                                {data.map((item, key) =>
                                    <Text key={key} style={{ margin: 2 }}>
                                        <Text style={{ fontWeight: 500 }}>Detalle: </Text>{item['detalle']}
                                    </Text>
                                )}
                            </>
                        }
                    </View>
                }

                <View style={styles.table} wrap>
                    <View style={{ ...styles.row, paddingTop: 20, fontWeight: 500, borderBottom: '2px solid #000' }} fixed>
                        {headers[info.title].grDetails.map((item, index) => {
                            return (<Text style={{ ...styles.column, ...item.style, fontWeight: 500 }} key={index}>{item.name}</Text>)
                        })}
                    </View>

                    {details.length !== 0
                        ? details.map((data, index) => {
                            const columnStyle = index % 2 === 0 ? styles.columnEven : styles.column
                            return (
                                <View style={styles.row} key={index}>
                                    {headers[info.title].grDetails.map((property, key) => <Text style={{ ...columnStyle, ...property.style }} key={key}><FormatDesktop data={data} property={property} /></Text>)}
                                </View>
                            )
                        }
                        )
                        : <View>
                            <Text>No hay entradas para mostrar</Text>
                        </View>
                    }
                </View>

                <View style={styles.total} fixed>
                    <View style={{ ...styles.info, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                        {data.map(item => headers[info.title].grData.map((property, key) =>
                            <View key={key} style={{ width: 180, margin: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontWeight: 500 }}>{property.name}</Text>
                                <Text>{property.field === 'detalle' ? item[property.field] : formatPrice(item[property.field])}</Text>
                            </View>
                        ))}
                    </View>
                </View>


                <View style={{ ...styles.footer }}>
                    <Text style={{ textAlign: 'right' }} render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
                    <Text style={{ position: 'absolute', bottom: 10, fontSize: 10, textAlign: 'left', color: '#8C979A', }}>{`${info.receipt}_Reporte-${info.title}${id}-PDF.pdf`}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default VerticalPDF