import React from 'react'
import { Browser } from '@syncfusion/ej2/base';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, Inject } from '@syncfusion/ej2-react-charts';
import { useStateContext } from '../contexts/ContextProvider';

const PieRadius = ({ id, title, data }) => {
    const { themeColors } = useStateContext();
    let pie;

    return (
        <AccumulationChartComponent id={id} ref={pie}
            enableSmartLabels={true}
            title={title}
            titleStyle={{
                color: themeColors.primary
            }}
            enableBorderOnMouseMove={false}
            enableAnimation={true}
            background='transparent'
            tooltip={{ enable: true, format: "<b>${point.x}</b><br/>Cantidad <b>${point.y} </b> <br/>" }}
            legendSettings={{
                background: 'white',
                visible: true,
                reverse: true
            }}>
            <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]} />
            <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective dataSource={data} xName='x' yName='y' innerRadius='20%' tooltipMappingName='r' dataLabel={{
                    visible: true, position: Browser.isDevice ? 'Inside' : 'Outside', name: 'text', enableRotation: true,
                    font: {
                        fontWeight: '600',
                    },
                    connectorStyle: { length: '20px', type: 'Curve' }
                }} radius='r'>
                </AccumulationSeriesDirective>
            </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
    )
}

export default PieRadius