import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { SEO, Title } from '../components';
import { reports } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

const Reports = () => {
  const { themeColors } = useStateContext();
  const activeLink = 'pt-3 pb-2.5 rounded-lg text-white text-md m-2';
  const normalLink = 'pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-400 dark:hover:text-white hover:text-black m-2';

  return (
    <>
      <SEO title='Reportes' />
      <div className='relative m-2 md:m-10 mt-24 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl'>
        <Title category="Mis" title="Reportes" />
        <div className='absolute mt-6 right-4 top-8 rounded-lg flex justify-between'>
          {reports.map(link => (
            <NavLink
              to={link.url}
              key={link.url}
              style={({ isActive }) => ({ color: isActive ? themeColors?.primary : '' })}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <span className="capitalize ">{link.name}</span>
            </NavLink>
          ))}
        </div>
        <Outlet />
      </div>
    </>
  )
}

export default Reports