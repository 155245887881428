import React, { useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";

const Pagination = ({
  range,
  setPage,
  page,
  slice,
  data,
  pageRows,
  setRows,
}) => {
  const { themeColors } = useStateContext();

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  const activeLink =
    "py-2 px-3 text-white dark:border-gray-700 dark:bg-gray-700 dark:text-white";
  const normalLink =
    "py-2 px-3 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white";

  // Función para generar el rango de páginas con puntos suspensivos
  const generatePageRange = () => {
    const currentPage = page;
    const lastPage = Math.ceil(data.length / pageRows);

    if (lastPage <= 7) {
      return Array.from({ length: lastPage }, (_, i) => i + 1);
    }

    const pageRange = [1];

    if (currentPage >= 4) {
      pageRange.push("...");
    }

    for (let i = Math.max(currentPage - 2, 2); i <= Math.min(currentPage + 2, lastPage - 1); i++) {
      pageRange.push(i);
    }

    if (currentPage <= lastPage - 3) {
      pageRange.push("...");
    }

    pageRange.push(lastPage);

    return pageRange;
  };

  const renderedRange = generatePageRange();

  return (
    <div className="flex flex-col justify-center pt-4 sm:flex-row sm:justify-between items-center">
      <div className="flex gap-2 items-center">
        <select
          className="border border-black rounded-lg p-0.5"
          name="rowsPerPage"
          value={pageRows}
          onChange={(event) => setRows(event.target.value)}
        >
          <option value={1}>1</option>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </select>

        <span className="text-sm text-gray-700 dark:text-gray-400">
          Mostrando{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {page}
          </span>{" "}
          a{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {range.slice(-1)}
          </span>{" "}
          de{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {data.length}
          </span>{" "}
          Entradas
        </span>
      </div>

      <div className="inline-flex mt-2 xs:mt-0 border rounded-r">
        <button
          onClick={() => {
            const previousPage = Math.max(page - 1, 1);
            setPage(previousPage);
          }}
          className="py-2 px-4 text-sm font-medium rounded-l hover:text-blue-700 hover:bg-blue-100 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
        >
          Anterior
        </button>
        <div className="inline-flex -space-x-px">
          {renderedRange.map((element, index) => (
            <button
              key={index}
              aria-current={element === page ? "page" : ""}
              style={{
                backgroundColor: element === page ? themeColors.primary : "",
              }}
              className={element === page ? activeLink : normalLink}
              onClick={() => {
                if (typeof element === "number") {
                  setPage(element);
                }
              }}
            >
              {element}
            </button>
          ))}
          {/* {range.map((element, index) => (
            <button
              key={index}
              aria-current={page === element ? "page" : ""}
              style={{
                backgroundColor: page === element ? themeColors.primary : "",
              }}
              className={page === element ? activeLink : normalLink}
              onClick={() => {
                setPage(element);
              }}
            >
              {element}
            </button>
          ))} */}
        </div>
        <button
          onClick={() => {
            const next = data.length === 0 ? 1 : Math.ceil(data.length / pageRows)
            const nextPage = Math.min(page + 1, next);

            setPage(nextPage);
          }}
          className="py-2 px-4 text-sm font-medium rounded-l hover:text-blue-700 hover:bg-blue-100 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Pagination;
