import React, { useEffect, useState } from 'react';

import { Button, VerticalPDF } from './';
import { transferDetailsGrid } from '../data/dummy';
import { getDataByIdFrom } from '../services/GdrService';
import { useAuthContext } from '../contexts/ContextAuth';
import { useStateContext } from '../contexts/ContextProvider';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsFilePdf } from 'react-icons/bs';

const TransferDetails = ({ title, URL, setOpen, incomeID }) => {
    const { themeColors } = useStateContext();
    const { auth, handleErrors } = useAuthContext();
    const [detailsData, setDetailsData] = useState([]);
    const date = new Date();
    const aaaammdd = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${(date.getDate()).toString().padStart(2, '0')}`
    const hhmmss = date.toTimeString().substring(0, 2) + date.toTimeString().substring(3, 5) + date.toTimeString().substring(6, 8);
    const ddmmaaaa = `${aaaammdd.slice(6, 8).padStart(2, '0')}/${aaaammdd.slice(4, 6).padStart(2, '0')}/${aaaammdd.slice(0, 4)}`;
    const hh_mm_ss = `${hhmmss.slice(0, 2)}:${hhmmss.slice(2, 4)}:${hhmmss.slice(4, 6)}`
    const fullURL = `${URL}q/detallemovimiento/`

    useEffect(() => {
        const saveResponse = (data) => {
            if (Array.isArray(data)) {
                setDetailsData(data);
            } else {
                setDetailsData([data])
            }
        }

        const getRecords = async () => {
            await getDataByIdFrom(fullURL, incomeID, auth.token)
                .then(response => saveResponse(response.data))
                .catch(error => handleErrors(error))
        }
        getRecords();
    }, [auth, handleErrors, incomeID, fullURL])

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0 overflow-hidden'>
            <div className='h-screen flex items-center justify-center'>
                <div className='flex flex-col item gap-5 bg-white w-11/12 dark:bg-secondary-dark-bg sm:w-4/5 lg:w-3/5 p-5 rounded-3xl'>
                    <div className='flex justify-between px-2'>
                        <div className='dark:text-slate-100'>Detalles</div>
                        <div className='flex gap-2'>
                            <PDFDownloadLink
                                document={<VerticalPDF id={incomeID} info={{  auth: auth.token, title, ddmmaaaa, hh_mm_ss, receipt: `${aaaammdd}${hhmmss}` }} />}
                                fileName={`${aaaammdd}${hhmmss}_Reporte-${title}-PDF.pdf`}>
                                <TooltipComponent content='Exportar tabla a PDF' position="TopCenter">
                                    <Button customFunction={() => { }} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='' icon={<BsFilePdf />} />
                                </TooltipComponent>
                            </PDFDownloadLink>
                        </div>
                    </div>
                    <div>
                        <div className='flex justify-between gap-2 dark:text-slate-100 pb-2 border-b-2 dark:border-b-gray-200 border-b-black'>
                            {transferDetailsGrid.map((head, index) => <span key={index} className='w-40 text-center text-sm font-semibold tracking-wide' >{head.name}</span>)}
                        </div>
                        {detailsData.map((data, index) => (
                            <div key={index} className='flex justify-between gap-2 pt-2'>
                                {
                                    transferDetailsGrid.map((grid, index) =>
                                        <div key={index} className='w-40 text-center text-sm text-gray-700 dark:text-gray-100 whitespace-nowrap'>
                                            {data[grid.field]}
                                        </div>
                                    )
                                }
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-center items-center'>
                        <Button customFunction={() => { setOpen(false) }} borderColor={themeColors?.highEmphasis} color={themeColors?.highEmphasis} backgroundColor='transparent' width='1/4' text='Cerrar' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransferDetails