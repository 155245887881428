import React, { useState, useEffect } from 'react';

import { reportPerSupplier, reportPerSupplierProd, reportPerPrice, csvPerSupplier, csvPerSupplierProd, csvPerPrice, regEx } from '../../data/dummy';
import { Button, ErrorLabel, Input, Select, ReportsPDF, Banner } from '../../components';
import SelectOff from './SelectOff';
import { URL_INCOMEREPORT, URL_SUPPLIER, URL_PRODUCT } from '../../services/Api';
import { getDataBy } from '../../services/GdrService';
import { useAuthContext } from '../../contexts/ContextAuth';
import { useStateContext } from '../../contexts/ContextProvider';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { BsFilePdf } from 'react-icons/bs';
import { GrDocumentCsv } from 'react-icons/gr';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FormatDesktop } from '../../components/Table';

const Purchase = () => {
    const { auth } = useAuthContext();
    const { themeColors } = useStateContext();
    const date = new Date();
    const dateF = new Date();
    dateF.setMonth(dateF.getMonth() - 1)
    const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
    const [monthF, dayF, yearF] = [dateF.getMonth() + 1, dateF.getDate(), dateF.getFullYear()];
    const formatedDateFrom = `${yearF}-${monthF < 10 ? '0' + monthF : monthF}-${dayF < 10 ? '0' + dayF : dayF}`
    const formatedDateTo = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
    const aaaammdd = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${(date.getDate()).toString().padStart(2, '0')}`;
    const hhmmss = date.toTimeString().substring(0, 2) + date.toTimeString().substring(3, 5) + date.toTimeString().substring(6, 8);
    const ddmmaaaa = `${aaaammdd.slice(6, 8).padStart(2, '0')}/${aaaammdd.slice(4, 6).padStart(2, '0')}/${aaaammdd.slice(0, 4)}`;
    const hh_mm_ss = `${hhmmss.slice(0, 2)}:${hhmmss.slice(2, 4)}:${hhmmss.slice(4, 6)}`;

    const initialState = { value: '', error: null };
    const [report, setReport] = useState(initialState);
    const [supplier, setSupplier] = useState('');
    const [product, setProduct] = useState('');
    const [dateFrom, setDateFrom] = useState({ value: formatedDateFrom, error: null })
    const [dateTo, setDateTo] = useState({ value: formatedDateTo, error: null })
    const [recordsError, setRecordsError] = useState(initialState);
    const [info, setInfo] = useState({ grid: [] });
    const [records, setRecords] = useState([]);
    const [csv, setCSV] = useState([]);
    const [banner, setBanner] = useState(initialState);
    const { title } = info;
    const config = [
        { id: 'provfecha', nombre: 'Proveedores según Fecha', },
        { id: 'provfechadet', nombre: 'Detalle de Compra según Proveedor y Producto', },
        { id: 'preciohistorico', nombre: 'Precio Histórico', },
    ]

    useEffect(() => {
        let shadowBanner = setTimeout(() => setBanner({ error: null }), 2000);
        return () => { clearTimeout(shadowBanner) };
    });

    const selectGrid = (aReport) => {
        const info = {
            provfecha: { grid: reportPerSupplier, csv: csvPerSupplier, title: 'Proveedores según Fecha', },
            provfechadet: { grid: reportPerSupplierProd, csv: csvPerSupplierProd, title: 'Detalle de Compra según Proveedor y Producto', },
            preciohistorico: { grid: reportPerPrice, csv: csvPerPrice, title: 'Precio Histórico', },
        };

        setInfo(info[aReport])
    }

    const transformReport = (anObject, aReport) => {
        const getter = {
            provfecha: 'nombre',
            provfechadet: 'nombre_producto',
            preciohistorico: 'nombre_proveedor',
        };

        selectGrid(aReport)

        return anObject.reduce(function (reducer, array) {
            reducer[array[getter[aReport]]] = reducer[array[getter[aReport]]] || [];
            reducer[array[getter[aReport]]].push(array);
            return reducer;
        }, Object.create(null));
    }

    const handleReport = (aReport, aSupplier, aProduct) => {
        aSupplier = aSupplier === '' || aSupplier === undefined ? 0 : aSupplier;
        aProduct = aProduct === '' || aProduct === undefined ? 0 : aProduct;

        if (aReport.value !== '' && aReport.error === false) {
            const params = aReport.value === 'provfecha' ? aSupplier : aSupplier + '/' + aProduct;
            getDataBy(URL_INCOMEREPORT + aReport.value + '/' + params + '/' + dateFrom.value + '/' + dateTo.value, auth?.token)
                .then(response => {
                    if (response.data.length === 0) {
                        setRecords([])
                        setRecordsError({ value: `Ups! No hay datos para mostrar ${supplier.nombre === undefined ? '' : ' en' + supplier.nombre}`, error: false })
                    } else {
                        setRecords(transformReport(response.data, aReport.value))
                        setRecordsError({ error: false })
                    }
                    setCSV(response.data)
                    setSupplier({ id: '' })
                    aReport.value !== 'provfecha' && setProduct({ id: '' })
                    setBanner({ ...banner, value: { text: 'Reporte generado correctamente!', background: themeColors?.confirm }, error: true })
                })
                .catch(() => setRecordsError({ value: 'Ocurrió un error al generar el Reporte', error: true }))
        } else {
            setRecordsError({ value: 'Elija un Reporte para realizar la búsqueda', error: true })
        }
    }

    const handleCSV = () => {
        const aux = csv.map(item => {
            const record = {};
            info['csv'].forEach(column => {
                record[column.name] = item[column.field];
            });
            return record;
        });
        return aux;
    }

    return (
        <>
            <div className='absolute -top-14 -left-10'>
                {banner.error !== null && <Banner text={banner.value.text} backgroundColor={banner.value.background} setState={() => setBanner(initialState)} />}
            </div>
            <div className='flex gap-2'>
                <SelectOff data={config} id='report' label='Reporte' state={report} setState={setReport} />
                {report.value !== '' && <Select id='supplier' label='Proveedor (Sin definir para todos)' url={URL_SUPPLIER} state={supplier} setState={setSupplier} getter='nombre' />}
                {(report.value === 'provfechadet' || report.value === 'preciohistorico')
                    && <Select id='product' label='Producto (Sin definir para todos)' url={URL_PRODUCT} state={product} setState={setProduct} getter='nombre' />
                }
                {report.value !== '' &&
                    <>
                        <Input id='datefrom' type='date' size='small' required={true}
                            state={dateFrom} setState={setDateFrom} regEx={regEx.notEmpty} css='w-1/6' />
                        <Input id='dateto' type='date' size='small' required={true}
                            state={dateTo} setState={setDateTo} regEx={regEx.notEmpty} css='w-1/6' />
                    </>
                }

                <Button customFunction={() => handleReport(report, supplier.id, product.id)} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='1/2' text='Generar reporte' />
            </div>
            <div>
                <div className='text-center p-2 pt-6 text-xs'>
                    {recordsError.error === true && <ErrorLabel color={themeColors?.error}>{recordsError.value}</ErrorLabel>}
                    {recordsError.error === null && records.length === 0 && <span style={{ color: themeColors?.highEmphasis }}>Ups! No hay datos para mostrar aquí</span>}
                </div>
                {Object.keys(records).length > 0 &&
                    <div className='flex justify-end gap-2 mt-2'>
                        <PDFDownloadLink
                            document={<ReportsPDF header={info.grid} data={records} info={{ title, ddmmaaaa, hh_mm_ss, receipt: `${aaaammdd}${hhmmss}` }} />}
                            fileName={`${aaaammdd}${hhmmss}_Reporte-${info.title}-PDF.pdf`}>
                            <TooltipComponent content='Exportar tabla a PDF' position="TopCenter">
                                <Button customFunction={() => { }} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='' icon={<BsFilePdf />} />
                            </TooltipComponent>
                        </PDFDownloadLink>
                        <CSVLink data={handleCSV()} filename={`${aaaammdd}${hhmmss}_Reporte-${info.title}-CSV.csv`} separator=', ' enclosingCharacter={`'`} target='_blank'>
                            <TooltipComponent content='Exportar tabla a CSV' position="TopCenter">
                                <Button customFunction={() => { }} borderColor={themeColors?.primary} color={themeColors?.background} backgroundColor={themeColors?.primary} width='' icon={<GrDocumentCsv />} />
                            </TooltipComponent>
                        </CSVLink>
                    </div>
                }
                <div className='flex flex-col'>
                    {recordsError.error === false &&
                        (Object.keys(records).length > 0
                            ? Object.entries(records).map(([key, records]) => (
                                <div key={key}>
                                    <p style={{ color: themeColors?.primary }} className="m-3 mt-4 uppercase text-xl">
                                        {key}
                                    </p>
                                    <div style={{ width: 'calc(100 / 7% - 20px)' }} className='ml-6 overflow-auto rounded-lg shadow hidden md:block border'>
                                        <table className='w-full table-auto'>
                                            <thead className='bg-gray-50 dark:bg-secondary-dark-bg dark:text-gray-100 border-b-2 border-gray-200 dark:border-black'>
                                                <tr>
                                                    {info.grid.map((item, index) => (
                                                        <th key={index} className='p-3 text-sm font-semibold tracking-wide text-left'>{item.name}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {records.map((record, index) =>
                                                    <tr key={index} className='bg-white dark:bg-secondary-dark-bg odd:bg-gray-300 dark:odd:bg-neutral-700 hover:bg-neutral-100 dark:hover:bg-gray-500'>
                                                        {info.grid.map((property, key) =>
                                                            <td key={key} className='w-fit p-3 text-sm text-gray-700 dark:text-gray-100 whitespace-nowrap'>
                                                                <FormatDesktop data={record} property={property} />
                                                            </td>
                                                        )}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))
                            : <span className='text-center' style={{ color: themeColors?.highEmphasis }}>{recordsError.value}</span>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Purchase