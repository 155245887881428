import React, { useState, useEffect } from 'react';
import { LoadingSpinner, PieRadius, SEO, Title } from '../components';
import { useAuthContext } from '../contexts/ContextAuth';
import { useStateContext } from '../contexts/ContextProvider';
import { URL_STATS } from '../services/Api';
import { getDataFrom } from '../services/GdrService';

const Stats = () => {
  const { themeColors } = useStateContext();
  const { auth, handleErrors } = useAuthContext();
  const [loading, setLoading] = useState(true)
  const [records, setRecords] = useState({ chart1: [], chart2: [], chart3: [], chart4: [], chart5: [], chart6: [], chart7: [], chart8: [] });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function transformResponse(response) {
      const aux = [];
      const quantitys = response.map(item => parseFloat(item.cantidad))
      const maxNumber = Math.max(...quantitys);

      response.forEach(item => {
        const radius = (parseFloat(item.cantidad) * 180) / maxNumber;
        const selectedRadius = radius < 100 ? radius + 70 : radius;
        aux.push({ x: item.descripcion, y: parseFloat(item.cantidad), r: String(selectedRadius), text: item.descripcion })
      })

      return aux;
    }

    const getData = async (url, chart) => {
      try {
        const response = await getDataFrom(url, signal, auth.token);
        return { chart, data: transformResponse(response.data) };
      } catch (error) {
        handleErrors(error);
        return { chart, data: [] };
      }
    };

    const promises = [
      getData(URL_STATS + 'topTenProductByStock', 'chart1'),
      getData(URL_STATS + 'topTenProductByBuy', 'chart2'),
      getData(URL_STATS + 'topThreeVendorByCount', 'chart3'),
      getData(URL_STATS + 'topThreeVendorByAmount', 'chart4'),
      getData(URL_STATS + 'topThreeCustomerByCount', 'chart5'),
      getData(URL_STATS + 'topThreeCustomerByAmount', 'chart6'),
      getData(URL_STATS + 'topThreeTransferDestinationByCount', 'chart7'),
      getData(URL_STATS + 'topThreeTransferDestinationByAmount', 'chart8'),
    ];

    Promise.all(promises)
      .then(results => {
        const newRecords = results.reduce((acc, { chart, data }) => ({ ...acc, [chart]: data }), {});
        setRecords(prevState => ({ ...prevState, ...newRecords }));
      })
      .finally(() => setLoading(false))


    return () => { controller.abort(); };
  }, [auth, handleErrors])

  return (
    <>
      <SEO title='Estadísticas' />
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl'>
        <Title category="Mis" title="Estadísticas" />
        {loading
          ? <LoadingSpinner color={themeColors?.primary} />
          : <div className='flex flex-wrap justify-evenly'>
            <PieRadius title='Top 10 Productos en Stock' info='Productos' id='pie-chart-1' data={records.chart1} />
            <PieRadius title='Top 10 Productos más Comprados' info='Productos' id='pie-chart-2' data={records.chart2} />
            <PieRadius title='Top 3 Proveedores con más Compras' info='Proveedores' id='pie-chart-3' data={records.chart3} />
            <PieRadius title='Top 3 Proveedores con más Compras por Monto' info='Proveedores' id='pie-chart-4' data={records.chart4} />
            <PieRadius title='Top 3 Clientes con más Egresos' info='Clientes' id='pie-chart-5' data={records.chart5} />
            <PieRadius title='Top 3 Clientes con más Egresos por Monto' info='Clientes' id='pie-chart-6' data={records.chart6} />
            <PieRadius title='Top 3 Almacenes con más Cantidad de Egresos' info='Almacenes' id='pie-chart-8' data={records.chart8} />
            <PieRadius title='Top 3 Almacenes con más Cantidad de Egresos por Monto' info='Almacenes' id='pie-chart-7' data={records.chart7} />
          </div>}
      </div>
    </>
  )
}

export default Stats