import React from 'react'
import { useStateContext } from '../../contexts/ContextProvider';

const SelectOff = ({ data, id, label, state, setState, disabled, customFilter = item => item }) => {
    const { themeColors } = useStateContext();

    const handleChange = (event) => {
        setState({ ...state, value: event.target.value })
    }

    const handleValidation = (event) => {
        if (!event.target.value) {
            setState({ ...state, error: true });
        } else {
            setState({ ...state, error: false });
        }
    }

    const classSelect = `first:text-red-600 w-full p-2 bg-white dark:bg-secondary-dark-bg rounded-md border border-[#c4c4c4] dark:border-[#262626] focus:border-secondary-blue dark:focus:border-secondary-purple hover:border-secondary-blue dark:hover:border-secondary-purple`

    return (
        <select style={{ color: themeColors?.highEmphasis }} className={classSelect}
            id={id} onChange={handleChange}
            onBlur={handleValidation} onKeyUp={handleValidation}
            defaultValue='' value={state.id} disabled={disabled} >
            <option style={{ color: '#c4c4c4' }} value='' disabled>Elija un {label}</option>
            {
                data.filter(customFilter).map(opcion =>
                    <option key={opcion.id} value={opcion.id}>{opcion.nombre === undefined ? opcion.magnitud === undefined ? opcion.alicuota : opcion.magnitud : opcion.nombre}</option>
                )
            }
        </select>
    )
}

export default SelectOff